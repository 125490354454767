import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[cpAppFocusOnError]'
})
export class AppFocusOnErrorDirective {
  @Input('cpAppFocusOnError') condition: boolean;

  constructor(private elementRef: ElementRef) {}
  @HostListener('click', ['$event'])
  onClick() {
    if (this.condition) {
      this.focusOnFirstInvalidElement();
    }
  }

  focusOnFirstInvalidElement() {
    setTimeout(() => {
      const firstInvalidElement = this.findFirstInvalidElement();
      if (firstInvalidElement) {
        firstInvalidElement.focus();
      }
    }, 100);
  }

  findFirstInvalidElement(): HTMLInputElement {
    const form = this.elementRef.nativeElement.closest('form');
    const inputElements: HTMLInputElement[] = Array.from(form.querySelectorAll('input.ng-invalid'));
    const selectElements: HTMLInputElement[] = Array.from(form.querySelectorAll('select.ng-invalid'));
    const checkboxElements: HTMLInputElement[] = Array.from(form.querySelectorAll('input[type="checkbox"].ng-invalid'));
    const allInvalidElements: HTMLInputElement[] = [...inputElements, ...selectElements, ...checkboxElements];

    allInvalidElements.sort((a: HTMLInputElement, b: HTMLInputElement) => {
      return Array.from(a.parentNode.children).indexOf(a) - Array.from(b.parentNode.children).indexOf(b);
    });

    return allInvalidElements[0];
  }
}
