<dl>
  <dd>
    {{"Mercedes me Charge is powered by ChargePoint, Inc. to provide you access to charging stations around the country. The following steps will help you register for the service by creating a ChargePoint account. Here are a few things to know." | T}}
  </dd>

  <dt>{{"Service period and subscription fee" | T}}</dt>

  <dd>
    {{"Mercedes-Benz is pleased to include complimentary Mercedes me Charge service for a period of time based on model year starting from when the service was activated in the Mercedes me Portal, after which a subscription fee might apply. Please refer to Manage Services within Mercedes me to view service terms."|T}}
  </dd>

  <dt>{{"Charging fee" | T}}</dt>

  <dd>{{"Prices may vary depending on the operator of the charging station. Please refer to your Mercedes me connect service for more information." |T}}</dd>

  <dt class="d-none">{{"High-power charging" |T}}</dt>

  <dd class="d-none">
    {{"Your Mercedes me Charge membership gives you exclusive access to %dcPorts% high-power charging stations for faster charge times." |T : {dcPorts:dcPorts} }}
  </dd>

  <dt class="d-none">{{"Free charging bonus"| T}} </dt>

  <dd class="d-none">{{"Mercedes me Charge is happy to offer you an exclusive starting balance of %initialBalance% toward your first charges." | T:{initialBalance:initialBalance} }}</dd>
</dl>
