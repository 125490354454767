import { EventEmitter, Injectable } from '@angular/core';
import angularData from '../../../angular.json';
import { BehaviorSubject } from 'rxjs';
const definedThemes = angularData.projects[
  'cp-signup'
].architect.build.options.styles.map((style) => style.bundleName);

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  theme = 'default';
  themeChanged = new EventEmitter<string>();
  private _showLogoSubject = new BehaviorSubject<boolean>(false);
  showPoweredByLogo$ = this._showLogoSubject.asObservable();

  constructor() {
    let theme = 'default';

    // Look for theme override in query param
    const urlSearchParams = new URLSearchParams(window.location.search);
    const isMercedesSubdomain = this.isMercedesSubdomain();
    if (urlSearchParams.has('brand') || isMercedesSubdomain) {
      if (isMercedesSubdomain) {
        theme = 'daimler';
      } else {
        theme = urlSearchParams.get('brand');
      }
    }

    // Make sure it's one of our supported defined themes in angular.json file
    if (definedThemes.indexOf(theme) === -1) {
      theme = 'default';
    }

    this.updateTheme(theme);

    this.themeChanged.subscribe((themeName) => {
      if (themeName === 'daimler') {
        this._showLogoSubject.next(true);
      } else {
        this._showLogoSubject.next(false);
      }
    });
  }

  /**
   * helper method to determine if the current subdomain is a mercedes subdomain
   * to avoid the delay of the api call to get the brand
   * @returns boolean
   */
  private isMercedesSubdomain(): boolean {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return urlSearchParams.has('subdomain') ? !!urlSearchParams.get('subdomain').match(/mercedes/gi) : false;
  }

  setTheme(theme: string) {
    this.theme = theme;
    this.themeChanged.emit(theme);
  }

  getTheme() {
    return this.theme;
  }

  getAllThemes() {
    return definedThemes;
  }

  hasTheme(theme: string) {
    return definedThemes.indexOf(theme) !== -1;
  }

  updateTheme(theme: string) {
    if (document.getElementById('themeCSS')) {
      document.getElementById('themeCSS').remove();
    }
    this.setTheme(theme);
    this.addThemeCSS(theme);
  }

  addThemeCSS(theme: string) {
    const cssFile = `/${theme}.css`;
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = cssFile;
    link.id = 'themeCSS';
    link.onerror = () => {
      if (theme !== 'default') {
        this.addThemeCSS('default');
      }
    };
    const body = document.getElementsByTagName('body')[0];
    body.insertBefore(link, body.childNodes[0]);
  }



}
