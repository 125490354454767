import { Component, OnInit } from '@angular/core';
import { LoginService } from '../login.service';
import { CommunicationService } from 'src/app/services/communication.service';
import { FormControl, Validators } from '@angular/forms';
import { LinkAccountService } from 'src/app/services/link-account.service';
import { UserProfileService } from '../../user-profile/user-profile.service';
import { Agreement } from '../../user-profile/user-profile.component';
import { DriverSignupUXService } from '../../services/driver-signup-ux.service';
import { UiFlowStateService } from '../../services/ui-flow-state.service';

@Component({
  selector: 'cp-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loggedIn = false;
  username = new FormControl('', Validators.required);
  password = new FormControl('',  Validators.required);
  privacyPolicyAccept = new FormControl('',  Validators.required);
  showErrors = false;
  serverError = '';
  showLoader = false;
  sessionId: string = null;
  partnerTermsOfServiceLink: string = null;

  constructor(
    private loginSvc: LoginService,
    private commSvc: CommunicationService,
    private linkedAccountSvc: LinkAccountService,
    private profileSvc: UserProfileService,
    private stateSvc: UiFlowStateService,
    private configService: DriverSignupUXService
  ) { }

  ngOnInit() {
    const masterObject = this.commSvc.getMasterObject();
    this.commSvc.getComponentDefinition('session').subscribe((sessionDef) => {
      if (sessionDef) {
        sessionDef.fields.forEach(field => {
          if (field.id === 'sessionId' && typeof field.value !== 'undefined') {
            this.sessionId = field.value;
          }
        });
      }
    });
    if (typeof masterObject.signupEmail !== 'undefined') {
      this.username.setValue(masterObject.signupEmail);
    }
    if (typeof masterObject.selectedCountryObj !== 'undefined') {
      this.profileSvc.getAgreements(masterObject.selectedCountryObj.code).subscribe((agreements: Array<Agreement>) => {
        agreements.forEach(agreement => {
          const name = agreement.name.toLowerCase();

          if (name.includes('termsofservice') && name.includes('partner')) {
            this.partnerTermsOfServiceLink = '<a target="_blank" href="' + agreement.webUrl + '">' + agreement.localizedName + '</a>';
          }
        });
      });
    }
  }

  login() {
    if (this.username.valid && this.password.valid) {
      this.showLoader = true;
      this.loginSvc.login(this.username.value, this.password.value).subscribe((resp: any) => {
        // do some logic based on response
        if (resp && !resp.error) {
          this.loggedIn = true;
          if (this.sessionId) {
            // link the accounts
            this.linkedAccountSvc.linkAccounts(
              resp.email,
              this.password.value,
              this.sessionId,
              this.username.value
            ).subscribe(linkResp => {
              if (linkResp && linkResp.error) {
                // force to fill payment in DEN flow
                if (linkResp.error.error_id === 368 && linkResp.error.error_category === 'PROFILE') {
                  const signupGetParams = this.commSvc.getMasterObject().urlSearchParams;
                  signupGetParams.sessionid = this.sessionId;
                  this.stateSvc.clearSessionStorage();
                  this.configService.loadDriverSignUpConfiguration('', signupGetParams).subscribe(response => {
                    if (response.components?.length) {
                      this.stateSvc.setUXConfig(response, true);
                      const masterObj = this.stateSvc.getMasterObject();
                      if (!masterObj['givenName'] || !masterObj['familyName']) {
                        masterObj['givenName'] = resp.sessionObj.userFirstName;
                        masterObj['familyName'] = resp.sessionObj.userFullName.split(' ')[1];
                        this.stateSvc.updateSignUpData(masterObj);
                      }
                      this.stateSvc.prevRoute();
                    } else if (response.error) {
                      this.loggedIn = false;
                      this.serverError = response.message;
                    }
                    this.showLoader = false;
                  });
                } else {
                  this.showLoader = false;
                  this.loggedIn = false;
                  this.serverError = linkResp.error.error_message;
                }
              } else {
                // login second time to refresh session cache for template settings
                this.loginSvc.login(this.username.value, this.password.value).subscribe(() => {
                  this.showLoader = false;
                  this.commSvc.navToLastComponent();
                });
              }
            });
          }
        } else {
          this.showLoader = false;
          this.loggedIn = false;
          this.serverError = resp.error;
        }
      });
    } else {
      this.showErrors = true;
    }
  }

  getForgotPasswordUrl() {
    return this.loginSvc.getForgotPasswordUrl();
  }
}
